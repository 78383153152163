<template>
  <div class="w-100">
    <md-card
      v-if="!token"
      class="w-100 text-center"
    >
      <md-card-header class="py-5">
        <md-icon class="md-size-3x text-warning">
          warning_amber
        </md-icon>
        <h3 class="title">
          Payment Not Found
        </h3>
        <h5 class="category">
          Contact your agent plase.
        </h5>
      </md-card-header>
    </md-card>

    <md-card
      v-else-if="stripe_paid"
      class="w-100 text-center"
    >
      <md-card-header class="py-5">
        <md-icon class="md-size-3x text-warning">
          warning_amber
        </md-icon>
        <h3 class="title">
          Payment is already Paid
        </h3>
        <h5 class="category">
          If you think this is a mistake, please contact your agent.
        </h5>
      </md-card-header>
    </md-card>

    <md-card
      v-else
      class="w-100 text-center"
    >
      <md-card-header class="py-5">
        <md-icon class="md-size-3x text-info">
          store
        </md-icon>
        <h3 class="title">
          Payment Checkout
        </h3>
        <h4>Total: {{ moneyFormat(total) }}</h4>
        <md-button
          class="md-info"
          @click="goToCheckout"
        >
          GO TO CHECKOUT
        </md-button>
        <h5 class="category">
          Please continue the checkout process by clicking the button above
        </h5>
      </md-card-header>
    </md-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    total: 0,
    token: null,
    stripe_paid: true,
  }),
  created() {
    const stripeScript = document.createElement('script');
    stripeScript.setAttribute('id', 'stripe-script');
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
    document.head.appendChild(stripeScript);
  },
  destroyed() {
    const stripeScript = document.getElementById('stripe-script');
    stripeScript.parentNode.removeChild(stripeScript);
  },
  mounted() {
    this.getPaymentDetails();
  },
  methods: {
    getPaymentDetails() {
      this.guestRequest(`payment/${this.$route.params.token}`, 'get', null, ({ data }) => {
        this.total = data.total;
        this.token = data.button_token;
        this.stripe_paid = data.stripe_paid;
      });
    },
    goToCheckout() {
      // eslint-disable-next-line no-undef
      const stripe = Stripe(this.$schoolSettings.stripe_public);

      this.guestRequest(`payment/${this.$route.params.token}/start_checkout`, 'get', null, ({ data }) => {
        stripe.redirectToCheckout({ sessionId: data });
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .md-card-header {
  h3.title {
    color: #3c4858 !important;
    margin-top: 10px;
  }
  h4 {
    font-size: 1.5em;
  }
  h5.category {
    margin-bottom: 0;
  }
  small {
    display: inline-block;
    margin-bottom: 20px;
  }
}
</style>
